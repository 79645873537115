var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-layout",
    { staticClass: "components-layout-demo-custom-trigger" },
    [
      _c(
        "a-layout-sider",
        { attrs: { breakpoint: "lg", "collapsed-width": "0" } },
        [
          _c(
            "div",
            { staticClass: "logo" },
            [
              _vm.adherant && _vm.adherant.profile_picture
                ? _c("img", {
                    staticClass: "profile_pic",
                    attrs: {
                      src: _vm.adherant.profile_picture_url,
                      alt: "avatar"
                    }
                  })
                : _c("a-avatar", {
                    staticClass: "user_icon",
                    attrs: { icon: "user" }
                  }),
              _c(
                "a-row",
                [
                  _c("a-col", { attrs: { xs: { span: 0 }, md: 24 } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.get(_vm.user, "full_name")) +
                        "\n        "
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-menu",
            {
              attrs: {
                theme: "dark",
                mode: "inline",
                selectedKeys: _vm.selectedKeys
              },
              on: { select: _vm.menuItemChanged }
            },
            [
              _c(
                "a-menu-item",
                { key: "1" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/adherent/" } },
                    [
                      _c("a-icon", { attrs: { type: "dashboard" } }),
                      _c("span", [_vm._v("Tableau de bord")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "2" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/adherent/communication" } },
                    [
                      _c("a-icon", { attrs: { type: "notification" } }),
                      _c("span", [_vm._v("Communication")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "3" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/adherent/profil" } },
                    [
                      _c("a-icon", { attrs: { type: "user" } }),
                      _c("span", [_vm._v("Profil")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "4" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/adherent/etat-activite" } },
                    [
                      _c("a-icon", { attrs: { type: "clock-circle" } }),
                      _c("span", [_vm._v("Etat Activites")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "5" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/adherent/attestations" } },
                    [
                      _c("a-icon", { attrs: { type: "copy" } }),
                      _c("span", [_vm._v("Demande Attestation")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "6" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/adherent/demandes-ouvertures" } },
                    [
                      _c("a-icon", { attrs: { type: "form" } }),
                      _c("span", [_vm._v("Demandes Ouvertures")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "7" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/adherent/documents" } },
                    [
                      _c("a-icon", { attrs: { type: "file-pdf" } }),
                      _c("span", [_vm._v("Mes Documents")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "8" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/adherent/cotisation" } },
                    [
                      _c("a-icon", { attrs: { type: "dollar" } }),
                      _c("span", [_vm._v("Cotisations")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "9" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/adherent/messagerie" } },
                    [
                      _c("a-icon", { attrs: { type: "mail" } }),
                      _c("span", { staticStyle: { "margin-right": "8px" } }, [
                        _vm._v("Messagerie")
                      ]),
                      _c("a-badge", {
                        attrs: {
                          count: _vm.unreadMsgCount,
                          numberStyle: { backgroundColor: "#009688" }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "10" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/adherent/login" } },
                    [
                      _c("a-icon", { attrs: { type: "logout" } }),
                      _c("span", [_vm._v("Déconnexion")])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-layout",
        [
          _c(
            "a-layout-content",
            {
              staticClass: "content_layout",
              style: {
                margin: "24px 16px",
                padding: "24px",
                background: "#fff",
                "min-height": "max-content"
              }
            },
            [_c("router-view")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }