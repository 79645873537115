<template>
  <a-layout class="components-layout-demo-custom-trigger">
       <a-layout-sider
      breakpoint="lg"
      collapsed-width="0"
    >
      <div class="logo">
        <img
          v-if="adherant && adherant.profile_picture"
          :src="adherant.profile_picture_url"
          alt="avatar"
          class="profile_pic"
        />
        <a-avatar v-else icon="user" class="user_icon" />
        <a-row>
          <a-col :xs="{ span: 0 }" :md="24">
            {{ get(user, "full_name") }}
          </a-col>
        </a-row>
      </div>
      <a-menu
        theme="dark"
        mode="inline"
        @select="menuItemChanged"
        :selectedKeys="selectedKeys"
      >
        <a-menu-item key="1">
          <router-link to="/adherent/">
            <a-icon type="dashboard" />
            <span>Tableau de bord</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="2">
          <router-link to="/adherent/communication">
            <a-icon type="notification" />
            <span>Communication</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="3">
          <router-link to="/adherent/profil">
            <a-icon type="user" />
            <span>Profil</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="4">
          <router-link to="/adherent/etat-activite">
            <a-icon type="clock-circle" />
            <span>Etat Activites</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="5">
          <router-link to="/adherent/attestations">
            <a-icon type="copy" />
            <span>Demande Attestation</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="6">
          <router-link to="/adherent/demandes-ouvertures">
            <a-icon type="form" />
            <span>Demandes Ouvertures</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="7">
          <router-link to="/adherent/documents">
            <a-icon type="file-pdf" />
            <span>Mes Documents</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="8">
          <router-link to="/adherent/cotisation">
            <a-icon type="dollar" />
            <span>Cotisations</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="9">
          <router-link to="/adherent/messagerie">
            <a-icon type="mail" />
            <span style="margin-right: 8px">Messagerie</span>
            <a-badge
              :count="unreadMsgCount"
              :numberStyle="{ backgroundColor: '#009688' }"
            />
          </router-link>
        </a-menu-item>
        <a-menu-item key="10">
          <router-link to="/adherent/login">
            <a-icon type="logout" />
            <span>Déconnexion</span>
          </router-link>
        </a-menu-item>
        <!-- <a-menu-item key="6">
          <router-link to="/adherent/test">
            <a-icon type="logout"/>
            <span>Test</span>
          </router-link>
        </a-menu-item>-->
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-content
        class="content_layout"
        :style="{
          margin: '24px 16px',
          padding: '24px',
          background: '#fff',
          'min-height': 'max-content',
        }"
      >
        <router-view></router-view>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import auth from "@/services/auth";

export default {
  name: "AdherentHomePage",
  created() {
    this.getReceivedMessages();
    this.user = auth.getUser();
  },
  mounted() {
    this.selectElementFromSideBar();
  },
  data() {
    return {
      collapsed: false,
      user: null,
      unread_msg_count: 0,
      selectedKeys: ["1"],
    };
  },
  computed: {
    ...mapGetters({
      unreadMsgCount: "getTotalUnreadMessages",
    }),
    ...mapState({ adherant: (state) => state.adherent.adherent }),
  },
  methods: {
    selectElementFromSideBar() {
      if (localStorage.getItem("selectedAdherentMenuKey")) {
        let selectedAdminMenuKey = localStorage.getItem(
          "selectedAdherentMenuKey"
        );
        this.selectedKeys = [selectedAdminMenuKey];
      }
    },
    menuItemChanged({ item, key, selectedKeys }) {
      this.selectedKeys = [key];
      localStorage.setItem("selectedAdherentMenuKey", key);
    },
    ...mapActions({
      getReceivedMessages: "fetchReceivedMessages",
    }),
  },
};
</script>
<style>
.trigger {
  height: fit-content !important;
  color: white;
  text-align: right;
  margin-right: 20% !important;
  font-size: 1.7em;
}
.ant-layout {
  background-color: white !important;
}
.components-layout-demo-custom-trigger .trigger {
  padding-left: 70%;
  padding-right: 20%;
  padding-top: 0%;
}
.ant-form-item-label {
  text-align: start !important;
}
.profile_pic {
  width: 32px;
  height: 32px;
  border-radius: 100%;
}
</style>